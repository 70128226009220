.App {
  text-align: center;
  display: flex;
  margin: 1rem Min(10%, 12rem);
}

@media (max-width: 650px) {
  .App {
    flex-direction: column;
  }
}

