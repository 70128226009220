fieldset {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
fieldset label input {
    margin-left: 2rem;
}

.gridRows {
    display: grid;
    flex-direction: column;
    grid: 1fr/ 1fr 1fr;
}
  
.gridRows label {
    text-align: left;
    margin-left: min(3rem, 5vw);
    min-width: 10rem;
}